<template>
  <div>
    <Header></Header>
    <div class="container mx-auto py-4">
      <breadcrumb></breadcrumb>
      <div class="border border-gray-400 rounded-3xl mt-2 px-6 pt-4 pb-6">
        <div class="flex flex-row justify-between">
          <div class="flex items-center">
            <div class="text-primary font-bold text-xl">Wishlist</div>
            <div
              class="flex items-center bg-gray-100 rounded-lg w-36 md:w-64 px-2 py-1 ml-8"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
              <input
                v-model="search"
                class="bg-transparent focus:outline-none w-full ml-2"
                placeholder="Search package"
              />
            </div>
          </div>
          <div class="flex flex-row items-center gap-2">
            <font-awesome-icon
              icon="fa-solid fa-filter"
              class="sm:hidden block ml-8"
            />
            <div class="font-bold text-sm hidden sm:block">Filter by type</div>
            <div class="flex">
              <select
                id="select-kete"
                class="border border-gray-300 text-gray-900 text-sm rounded-xl block w-full px-2.5 py-0.5"
                v-model="jenis"
                @change="loadWishlist()"
              >
                <option value="0">All</option>
                <option value="1">Seadoo Rental</option>
                <option value="2">Seadoo Safari</option>
                <option value="3">Off-Road Safari</option>
                <option value="4">On-Road Safari</option>
                <option value="5">Sky Adventure</option>
              </select>
            </div>
          </div>
        </div>
        <div
          class="md:grid md:grid-cols-4 gap-6 mt-8 flex flex-col"
          v-if="filteredWishlist.length > 0"
        >
          <div
            v-for="wl in filteredWishlist"
            :key="wl.id"
            class="rounded-xl overflow-hidden border border-gray-200 shadow-lg"
          >
            <div
              class="font-medium text-sm bg-primary text-white text-center py-1"
            >
              {{ wl.package.nama }}
            </div>
            <div class="grid grid-cols-5 grd">
              <div class="col-span-2">
                <div class="p-2 h-full relative">
                  <img
                    :src="wl.package.galery_package"
                    class="object-cover w-full h-full max-h-32 rounded-xl border border-gray-300"
                  />
                  <span
                    class="absolute bottom-2 right-4 text-xl text-white cursor-pointer"
                    @click="removeWishlist(wl)"
                    ><font-awesome-icon icon="fa-solid fa-trash"
                  /></span>
                </div>
              </div>
              <div class="col-span-3 pt-2 pr-2 flex flex-col justify-between">
                <div>
                  <!-- <div class="text-sm font-semibold">{{ wl.nama_toko }}</div> -->
                  <!-- <div class="flex justify-between mt-2">
                                        <div class="flex items-start whitespace-nowrap">
                                            <font-awesome-icon icon="fa-regular fa-clock" size="sm" />
                                            <div class="text-xs font-medium ml-1">
                                                {{ wl.durasi }} {{ wl.durasi == 1 ? 'Day' : 'Days' }}
                                            </div>
                                        </div>
                                        <div class="flex items-center justify-end">
                                            <img :src="getLevelImage(wl.level)" class="w-1/4" />
                                            <div class="text-xs font-medium ml-1">
                                                {{ getLevelText(wl.level) }}
                                            </div>
                                        </div>
                                    </div> -->
                  <!-- <div class="text-2xs mt-2 font-medium">
                                        <div>Categories :</div>
                                        <div>{{ wl.category }}</div>
                                    </div> -->
                  <div class="mt-2 text-md font-bold truncate" :title="wl.package.toko">
                    <div class="truncate">{{ wl.package.nama_toko }}</div>
                  </div>
                  <div
                    class="mt-2 text-sm font-medium truncate"
                    :title="wl.package.meeting_point"
                  >
                    <div class="truncate">{{ wl.package.meeting_point }}</div>
                  </div>
                  <div class="text-xs mt-2 font-medium">
                    Start from :
                    <span class="text-sm ml-1 font-bold">
                      {{ wl.package.start_price | toCurrency }}
                    </span>
                  </div>
                </div>
                <button
                  class="bg-yellow hover:bg-yellow-darker w-full my-2 py-1 rounded-lg font-bold"
                  type="button"
                  @click="openSpecialOfferDetail(wl)"
                >
                  BOOK NOW
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="grid mt-8 font-bold text-center text-xl" v-else>
          You dont have any wishlist...
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import Footer from "@/components/Footer";
import { Slugify } from "../utils";

export default {
  name: "Wishlist",

  components: {
    Header,
    Breadcrumb,
    Footer,
  },

  data() {
    return {
      search: "",
      jenis: 0,
      wishlist: [],
    };
  },

  computed: {
    filteredWishlist() {
      return this.wishlist.filter(
        (val) =>
          val.package.nama.toLowerCase().includes(this.search.toLowerCase()) ||
          val.package.store.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },

  created() {
    this.$store.commit("setBreadcrumbItems", [
      { text: "Home", routeName: "Home" },
      { text: "Wishlist", routeName: "Wishlist" },
    ]);
    this.loadWishlist();
  },

  methods: {
    async loadWishlist() {
      this.$store.commit("setOverlayLoading", true);
      try {
        
        const response = await this.$http.get(
          `${this.$apiTripweWeb}/wishlist`, {
            params: {
              jenis: this.jenis
            }
          }
        );

        this.wishlist = response.data.data.item ?? [];
        
        // const response = await this.$http.post(
        //   `${this.$apiTripweWeb}/apptripwe/wishlist/getwishlist`,
        //   {
        //     user: this.$store.state.user?.id_user ?? "",
        //     jenis: this.jenis,
        //   }
        // );

        // this.wishlist = response.data.data;
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async openSpecialOfferDetail(d) {
      try {
        this.$store.commit("setOverlayLoading", true);
        var res = await this.$http.post(`${this.$apiTripweWeb}/package/detail/all`, {
          id_paket: d?.idpaket ?? "",
        });
        if (res.data.status == 200) {
          var pack = res.data.data;
          this.$router.push({
            name: "PackageDetail",
            params: { slug: Slugify(pack.nama), pack },
          });
        }else{
          throw res.data.message;
        }
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async removeWishlist(offer) {
      this.$store.commit("setOverlayLoading", true);
      var datapost = {
        id_wishlist: offer.id,
      };

      var res = await this.$http.post(
        `${this.$apiTripweWeb}/wishlist/delete`,
        datapost
      );
      if (res.data.status == 20000) {
        this.loadWishlist();
      }
      this.$store.commit("setOverlayLoading", false);
    },
    getLevelImage(level) {
      switch (parseInt(level)) {
        case 1:
          return require("@/assets/icons/ic-level-beginner.svg");
        case 2:
          return require("@/assets/icons/ic-level-intermediate.svg");
        default:
          return require("@/assets/icons/ic-level-advance.svg");
      }
    },
    getLevelText(level) {
      switch (parseInt(level)) {
        case 1:
          return "Beginner";
        case 2:
          return "Intermediate";
        default:
          return "Advance";
      }
    },
  },
};
</script>
<style scoped>
.grd {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 37%,
    #1f7882 142%
  );
}
</style>
